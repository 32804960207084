var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "c-card",
        { attrs: { title: "교육교재", height: "600px" } },
        [
          _c("template", { slot: "card-detail" }, [
            _c(
              "div",
              {
                staticClass:
                  "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
              },
              [_c("c-upload", { attrs: { editable: _vm.editable } })],
              1
            ),
          ]),
        ],
        2
      ),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }